<template>
  <div>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <div v-else id="about-page-wrapper">
      <div class="flex mt-4 mb-2">
        <h4 class="inline-block pt-3">{{ $t('tm') }}</h4>
        <vs-button v-if="uuid && !isEditing" type="border" @click.prevent="isEditing = true" class="ml-auto block">{{ $t('edit') }}</vs-button>
      </div>
      <quill-editor
        v-if="!uuid || isEditing"
        ref="myQuillEditor"
        class="editor"
        v-model="value"
        :options="editorOption"
        @focus="showErrMsg = false"
      />
      <div v-else class="content">
        <p v-html="value"></p>
      </div>
      <h4 class="mt-4 mb-2">{{ $t('en') }}</h4>
      <quill-editor
        v-if="!uuid || isEditing"
        ref="myQuillEditor"
        class="editor"
        v-model="value_en"
        :options="editorOption"
        @focus="showErrMsg = false"
      />
      <div v-else class="content">
        <p v-html="value_en"></p>
      </div>
      <h4 class="mt-4 mb-2">{{ $t('ru') }}</h4>
      <quill-editor
        v-if="!uuid || isEditing"
        ref="myQuillEditor"
        class="editor"
        v-model="value_ru"
        :options="editorOption"
        @focus="showErrMsg = false"
      />
      <div v-else class="content">
        <p v-html="value_ru"></p>
      </div>
      <div v-if="showErrMsg">
        <span class="text-danger">{{ $t('fillAllFields') }}</span>
      </div>
        <!-- @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)" -->
      <div class="vx-row px-4" v-if="!uuid || isEditing">
        <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">{{ $t('submit') }}</vs-button>
        <vs-button v-if="isEditing" type="border" color="warning" @click.prevent="cancelForm" class="mt-5 ml-4 block">{{ $t('cancel') }}</vs-button>
        <!-- <vs-button type="border" color="warning" @click.prevent="cancelForm" class="ml-4 mt-5 block">Cancel</vs-button> -->
      </div>
    </div>
  </div>
</template>

<script>
// import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor
  },
  data () {
    return {
      showErrMsg: false,
      isEditing: false,
      uuid: null,
      key: 'about',
      value: '',
      value_en: '',
      value_ru: '',
      editorOption: {
        debug: 'warn',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'align': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            ['link'],
            // [{ 'header': 1 }, { 'header': 2 }],
            // [{ 'script': 'sub'}, { 'script': 'super' }],
            // ['image', { 'font': [] }],
            ['clean']
          ],
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true
          }
        },
        placeholder: 'Compose an epic...',
        readOnly: true,
        theme: 'snow'
      },
      isLoading: true
    }
  },
  computed: {
    formValid () { return this.value !== '' && this.value_en !== '' && this.value_ru !== '' }
  },
  methods: {
    async fetchData () {
      this.$http.get('/texts', { params: { key: this.key } }).then(res => {
        if (res.data.length) {
          this.uuid = res.data[0].uuid
          this.value = res.data[0].value
          this.value_en = res.data[0].value_en
          this.value_ru = res.data[0].value_ru
        }
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    async cancelForm () {
      this.isEditing = false
      await this.fetchData()
    },
    async submitForm () {
      if (this.formValid) {
        if (!this.uuid) {
          await this.$http.post('/texts/add', {
            key: this.key,
            value: this.value,
            value_en: this.value_en,
            value_ru: this.value_ru
          }).then(() => {
            this.fetchData()
          })
        } else {
          await this.$http.patch(`/texts/edit/${this.uuid}`, {
            key: this.key,
            value: this.value,
            value_en: this.value_en,
            value_ru: this.value_ru
          }).then(res => {
            this.isEditing = false
            this.uuid = res.data.uuid
            this.value = res.data.value
            this.value_en = res.data.value_en
            this.value_ru = res.data.value_ru
          })
        }
      } else {
        this.showErrMsg = true
      }
    }
  },
  async created () {
    await this.fetchData()
  }
}
</script>

<style lang="scss">
#about-page-wrapper {
  .content {
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 15px;
    background-color: white;
    p {
      padding: 0px 2px;
      &.ql-indent-1 {
        padding-left: 30px;
      }
      &.ql-indent-2 {
        padding-left: 60px;
      }
      span {
        &.ql-size-small {
          font-size: 12px;
        }
        &.ql-size-large {
          font-weight: 500;
          font-size: 22px;
        }
        &.ql-size-huge {
          font-size: 32px;
          font-weight: 600;
        }
      }
      ul {
        // text-decoration: underline;
        padding-left: 30px;
        li {
          list-style-type: disc;
        }
      }
      ol {
        // text-decoration: underline;
        padding-left: 30px;
        li {
          list-style-type: decimal;
        }
      }
    }
  }
  .quill-editor.editor {
    height: 35vh;
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ccc;
    padding: 0 5px 10px 0;
    .ql-toolbar.ql-snow {
      border: unset !important;
      border-bottom: 1px solid #ccc;
    }
    .ql-container {
      // height: auto;
      ::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.1);
        border-radius: 10px;
        width: 6px;
        background-color: #F5F5F5;
      }
      ::-webkit-scrollbar {
        width: 6px;
        position: absolute;
        z-index: 1;
        background-color: #ffffff;
      }
      ::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #ccc;
        :hover {
          cursor: pointer !important;
        }
      }
    }
  }
}
</style>
